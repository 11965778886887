<template>
  <div class="order-creation-modal">
    <div class="row mobile-wrap-reverse">
      <div
        v-if="!isBookingPage && $store.getters['app/windowSize'] > 767"
        class="col-md-7"
        :class="{ 'disable-view': !phoneUserInfo.id || editStoreInfo.orderType === 'DINE_IN' }"
      >
        <menu-creation
          ref="menuCreationRef"
          :phone-user-info="editStoreInfo"
          :store-list="storeItemsData"
          :edit-store-info="editStoreInfo"
          @cart-info="userCardInfo"
        />
      </div>
      <div :class="isBookingPage ? 'col-md-12' : 'col-md-5'">
        <div class="row">
          <b-col
            :cols="isBookingPage ? 6 : $store.getters['app/windowSize'] > 767 ? 9 : 8"
            class="mb-1"
          >
            <b-form-group :label="''">
              <b-form-radio-group
                v-model="editStoreInfo.orderType"
                class="d-flex justify-content-start order-type-images"
                @change="
                  orderTypeChanged()
                  orderAddressChanged()
                "
              >
                <b-form-radio
                  class="custom-radio-right text-uppercase w-common-radio"
                  :class="{ 'no-radio': isBookingPage }"
                  :value="!isBookingPage ? 'POS' : 'DINE_IN'"
                >
                  <img
                    v-if="!isBookingPage"
                    class="icon-lable-radio"
                    :src="require('@/assets/images/app-icons/POS.jpeg')"
                  >
                  <img
                    v-else
                    class="icon-lable-radio"
                    :src="require('@/assets/images/app-icons/shop.png')"
                  >
                  <span>{{ !isBookingPage ? 'POS' : 'Dine IN' }}</span>
                </b-form-radio>
                <b-form-radio
                  v-if="!isBookingPage"
                  class="custom-radio-right text-uppercase w-common-radio"
                  value="DELIVERY"
                >
                  <img
                    class="icon-lable-radio"
                    :src="require('@/assets/images/app-icons/agent.png')"
                  >
                  <span>Delivery</span>
                </b-form-radio>
                <b-form-radio
                  v-if="!isBookingPage"
                  class="custom-radio-right text-uppercase w-common-radio"
                  value="TAKEAWAY"
                >
                  <img
                    class="icon-lable-radio"
                    :src="require('@/assets/images/app-icons/take-away.png')"
                  >
                  <span>Pickup</span>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            class="text-right"
            :class="{ 'pr-0': $store.getters['app/windowSize'] <= 767 }"
            :cols="isBookingPage ? 6 : $store.getters['app/windowSize'] > 767 ? 3 : 4"
          >
            <button
              class="btn btn-primary mr-1"
              style="padding-left: 10px; padding-right: 10px"
              :class="{ 'font-12-imp': $store.getters['app/windowSize'] <= 767 }"
              @click="createUser"
            >
              {{ $t('Create User') }}
            </button>
          </b-col>
          <b-col
            :md="12"
            class="phone-number-align mb-1 booking-page-phone"
            :class="{ 'd-flex align-items-end': isBookingPage }"
          >
            <b-input-group>
              <VuePhoneNumberInput
                ref="countryCodeRef"
                v-model="editStoreInfo.phoneNumber"
                required
                placeholder="+91 0000000000"
                :class="{ 'is-invalid': !editStoreInfo.phoneNumber && !editStoreInfo.email && isDataSubmitted }"
                @update="onUpdate"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="getUserByPhone"
                >
                  {{ $t('GO') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            :md="12"
            class="phone-number-align mb-1 booking-page-phone"
            :class="{ 'd-flex align-items-end': isBookingPage }"
          >
            <b-input-group>
              <b-form-input
                v-model="editStoreInfo.email"
                type="email"
                placeholder="Enter Email"
                :class="{ 'is-invalid': !editStoreInfo.phoneNumber && !editStoreInfo.email && isDataSubmitted }"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="searchByEmail"
                >
                  {{ $t('GO') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('First Name')">
              <b-form-input
                v-model="editStoreInfo.firstName"
                :class="{ 'is-invalid': !editStoreInfo.firstName && isDataSubmitted }"
                required
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Last Name')">
              <b-form-input
                v-model="editStoreInfo.lastName"
                required
                :class="{ 'is-invalid': editStoreInfo.orderType != 'POS' && !editStoreInfo.lastName && isDataSubmitted }"
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.orderType === 'DELIVERY'"
            :md="12"
          >
            <b-form-group :label="$t('Customer Location')">
              <b-input-group>
                <b-form-select
                  v-model="editStoreInfo.addressId"
                  :class="{ 'is-invalid': editStoreInfo.orderType != 'POS' && !editStoreInfo.addressId && isDataSubmitted }"
                  required
                  :options="userAddressOptions"
                  @change="orderAddressChanged"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    :disabled="!phoneUserInfo.id"
                    @click="createUserAddressModal"
                  >
                    {{ $t('Create Address') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.orderType === 'DELIVERY'"
            md="6"
          >
            <b-form-group :label="$t('Flat/Villa/Office No')">
              <b-form-input
                v-model="editStoreInfo.flat_no"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.orderType === 'DELIVERY'"
            md="6"
          >
            <b-form-group :label="$t('Building/Villa Name')">
              <b-form-input
                v-model="editStoreInfo.building"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Country')">
              <b-form-select
                v-model="editStoreInfo.country_id"
                disabled
                :options="countryOptions"
                @change="changeUserCity"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('City')">
              <b-form-select
                v-model="editStoreInfo.city_id"
                disabled
                :options="cityOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="6"
          >
            <b-form-group :label="$t('Payment Mode')">
              <b-form-select
                v-model="editStoreInfo.paymentMode"
                :class="{ 'is-invalid': !editStoreInfo.paymentMode && isDataSubmitted }"
                required
                :options="paymentModeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              v-if="!isBookingPage"
              :label="$t('Order Mode')"
            >
              <b-form-select
                v-model="editStoreInfo.without_item"
                :disabled="disableBookOrder"
                :options="itemMode"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="editStoreInfo.orderType === 'DELIVERY' && editStoreInfo.without_item == '1'"
            md="6"
          >
            <b-form-group :label="$t('COD Amount')">
              <b-form-input
                v-model="editStoreInfo.cod"
                required
                :class="{ 'is-invalid': !editStoreInfo.cod && isDataSubmitted }"
                type="number"
              />
            </b-form-group>
          </b-col>
          <div
            v-if="$store.getters['app/windowSize'] <= 767"
            class="col-md-12 text-right mb-1"
          >
            <button
              v-if="editStoreInfo.without_item == 0 && showMenuButton"
              class="btn btn-primary"
              :disabled="!phoneUserInfo.id"
              @click="createWithMenuModal = true"
            >
              {{ $t('Add Items') }}
            </button>
          </div>
          <b-col
            v-if="cartInfo && cartInfo.carts && cartInfo.carts.length > 0 && editStoreInfo.orderType != 'DINE_IN'"
            md="12"
            class="table-responsive"
          >
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{{ $t('Item') }}</th>
                  <th>{{ $t('Quantity') }}</th>
                  <th class="text-right">
                    {{ $t('Price') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) of cartInfo.carts"
                  :key="index"
                >
                  <td colspan="3">
                    <div class="d-grid grid-columns-template-wrapper">
                      <p class="mb-0">
                        {{ item.product.item_name }}
                      </p>
                      <p class="mb-0 text-right">
                        {{ item.product.item_price }} {{ storeCurrency }} x {{ item.quantity }}
                      </p>
                      <p class="mb-0 text-right">
                        {{ storeCurrency }}{{ $helpers.toFixed(item.item_price * item.quantity, 2) }}
                      </p>
                    </div>
                    <p
                      v-if="item.cartaddon && item.cartaddon.length > 0"
                      class="mb-0"
                    >
                      ({{ item.cartaddon.length }} {{ $t('addons') }})
                    </p>
                    <div
                      v-if="item.cartaddon && item.cartaddon.length > 0"
                      class="addons-wrapper"
                    >
                      <div
                        v-for="(addon, counter) of item.cartaddon"
                        :key="counter"
                        class="d-grid grid-columns-template-wrapper"
                      >
                        <p class="mb-0">
                          {{ addon.addon_name }}
                        </p>
                        <p class="mb-0 text-right">
                          {{ addon.addon_price }} {{ storeCurrency }} x {{ item.quantity }}
                        </p>
                        <p class="mb-0 text-right">
                          {{ storeCurrency }}{{ addon.addon_price * item.quantity }}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Cart Subtotal') }}: {{ storeCurrency }} {{ cartInfo.total_item_price }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Shop Offer') }}: {{ storeCurrency }} {{ shopOfferAmount }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Promocode Discount') }}: {{ storeCurrency }} {{ cartInfo.promocode_amount }}
                  </td>
                </tr>
                <tr v-if="editStoreInfo.discount_type && $helpers.toFixed(cartInfo.total_price_original - cartInfo.total_price) > 0">
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Discount') }}: {{ storeCurrency }} {{ $helpers.toFixed(cartInfo.total_price_original - cartInfo.total_price) }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    TAX: {{ storeCurrency }} {{ cartInfo.shop_gst }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Packing Charge') }}: {{ storeCurrency }} {{ cartInfo.shop_package_charge }}
                  </td>
                </tr>
                <tr>
                  <td
                    v-if="cartInfo.delivery_charges"
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Delivery Charge') }}: {{ storeCurrency }} {{ cartInfo.delivery_charges }}
                  </td>
                  <td
                    v-else
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Delivery Charge') }}: FREE
                  </td>
                </tr>
                <tr v-if="cartInfo.wallet_amount">
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Wallet') }}: {{ storeCurrency }} {{ cartInfo.wallet_amount }}
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ $t('Total Price') }}: {{ storeCurrency }} {{ cartInfo.total_price }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="6"
          >
            <b-form-group :label="$t('Discount')">
              <b-input-group>
                <b-form-select
                  v-model="editStoreInfo.promocode_id"
                  :options="userPromocodesOptions"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    class="px-1"
                    :disabled="!phoneUserInfo.id"
                    @click="applyPromocode"
                  >
                    <feather-icon
                      size="16"
                      icon="CheckIcon"
                    />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="px-1"
                    :disabled="!phoneUserInfo.id"
                    @click="removePromocode"
                  >
                    <feather-icon
                      size="16"
                      icon="XIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="3"
          >
            <b-form-group :label="$t('Percentage')">
              <b-form-input
                v-model="editStoreInfo.percentage"
                type="number"
                @blur="clearCoupon"
                @keyup="calculateTotal('PERCENTAGE')"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage"
            md="3"
          >
            <b-form-group :label="$t('Fixed')">
              <b-form-input
                v-model="editStoreInfo.fixed"
                type="number"
                @blur="clearCoupon"
                @keyup="calculateTotal('FIXED')"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.orderType === 'POS' && editStoreInfo.paymentMode === 'CASH'"
            md="4"
          >
            <b-form-group :label="$t('Total Amount')">
              <b-form-input
                :value="totalAmount"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.orderType === 'POS' && editStoreInfo.paymentMode === 'CASH'"
            md="4"
          >
            <b-form-group :label="$t('Received Amount')">
              <b-form-input
                v-model="editStoreInfo.received_amount"
                type="number"
                @keypress="$superApp.validations.numberOnly"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!isBookingPage && editStoreInfo.orderType === 'POS' && editStoreInfo.paymentMode === 'CASH'"
            md="4"
          >
            <b-form-group :label="$t('Return Amount')">
              <b-form-input
                :value="returnAmount"
                disabled
                type="text"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="editStoreInfo.orderType == 'DINE_IN'"
            md="12"
          >
            <custom-calender
              ref="calenderRef"
              :hide-submit="true"
              :timing="storeCurrentTimings"
              :all-timings="providerTimings"
              :store-slot-info="providerTimings"
            />
          </b-col>
          <b-col
            v-if="editStoreInfo.orderType == 'DINE_IN'"
            md="12"
            class="mt-1 mb-1"
          >
            <div class="d-flex wrap-store-options">
              <p class="mb-0">
                {{ $t('NO OF GUESTS') }}
              </p>
            </div>
            <b-form-spinbutton
              v-model="editStoreInfo.no_of_guest"
              class="mx-auto"
              min="1"
              size="sm"
            />
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Comments')">
              <ckeditor
                v-model="editStoreInfo.comments"
                :editor="editor"
              />
            </b-form-group>
          </b-col>
        </div>
        <b-col
          md="12"
          class="text-center my-2 d-flex justify-content-center"
        >
          <button
            v-if="phoneUserInfo.id"
            class="btn btn-primary mr-1"
            @click="createOrder"
          >
            {{ isBookingPage ? 'Book' : 'Create Order' }}
          </button>
        </b-col>
      </div>
    </div>
    <b-modal
      v-model="createUserAddressModalShow"
      :title="$t('Address Details')"
      :ok-title="$t('Save')"
      ok-only
      size="lg"
      hide-footer
      :no-close-on-backdrop="true"
      @ok="createUserAddress"
    >
      <address-modal
        ref="addressModal"
        @address-selected="setPlace"
        @set-address="setAdress"
        @save-details="
          () => {
            createUserAddressModalShow = false
            createUserAddress()
          }
        "
      />
    </b-modal>
    <b-modal
      id="menuCreationModal"
      v-model="createWithMenuModal"
      :no-close-on-backdrop="true"
      :title="$t('Choose from Menu')"
      :ok-title="$t('Save')"
      ok-only
      size="xl"
      @hidden="createWithMenuModal = false"
    >
      <div class="wrap-online-order-section">
        <cart-dropdown
          :is-shop-or-provider="true"
          :order-payload="cartInfo"
          class="store-cart-option-button text-right"
          @value-changed="valueChanged"
        />
        <menu-creation
          ref="menuCreationRef"
          :phone-user-info="editStoreInfo"
          :edit-store-info="editStoreInfo"
          :store-list="storeItemsData"
          @cart-info="userCardInfo"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BFormRadioGroup, BFormRadio, BFormGroup, BFormSpinbutton, BModal, BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormSelect } from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import moment from 'moment'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import AddressModal from '@/views/users/profile/AddressModal.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '@/views/users/useUser'
import eventBus from '@/libs/event-bus'
import CartDropdown from '@/@core/layouts/components/app-navbar/components/CartDropdown.vue'
import MenuCreation from './MenuCreation.vue'
import CustomCalender from '../../users/shared/CustomCalender.vue'
import { useStoreUi } from '../useStore'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    BCol,
    BModal,
    CustomCalender,
    VuePhoneNumberInput,
    BFormSpinbutton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BFormSelect,
    AddressModal,
    MenuCreation,
    CartDropdown,
  },
  props: {
    isBookingPage: {
      type: Boolean,
      default: false,
    },
    showMenuButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      promocodesList: [],
      isDataSubmitted: false,
      lastOrderType: '',
      createWithMenuModal: false,
      editStoreInfo: {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        country_id: '',
        city_id: '',
        flat: '',
        flatName: '',
        orderType: '',
        paymentMode: '',
        addressId: '',
        comments: '',
        schedule: '',
        without_item: 0,
        no_of_guest: 1,
      },
      cartInfo: {},
      markers: null,
      center: { lat: 45.508, lng: -73.587 },
      addressTypeInfo: [
        {
          label: 'Home',
          value: 'Home',
        },
        {
          label: 'Work',
          value: 'Work',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
      editor: ClassicEditor,
      phoneNumberPayload: {},
      countryOptions: [],
      cityOptions: [],
      countryPayload: [],
      phoneUserInfo: {},
      shouldCreateUser: 'order',
      userAddressInfo: {},
      createUserAddressModalShow: false,
      storeItemsData: [],
      disableBookOrder: false,
      providerTimings: [],
      storeCurrentTimings: null,
    }
  },
  computed: {
    totalAmount() {
      if (this.cartInfo && this.cartInfo.total_price) {
        return this.cartInfo.total_price
      }
      return 0
    },
    amountAfterDiscount() {
      if (!this.cartInfo) return 0
      return this.cartInfo.total_item_price - this.shopOfferAmount
    },
    shopOfferAmount() {
      if (!this.cartInfo) return 0
      const discount = !this.cartInfo.discount ? this.cartInfo.shop_discount : this.cartInfo.discount
      return discount || 0
    },
    returnAmount() {
      if (this.totalAmount && this.editStoreInfo.received_amount) {
        return this.editStoreInfo.received_amount > this.totalAmount ? this.parseTo(this.editStoreInfo.received_amount - this.totalAmount, 3) : 0
      }
      return 0
    },
    storeTimings() {
      if (!this.providerTimings) return null
      const currentDay = moment().format('ddd').toUpperCase()
      return this.providerTimings.find(x => x.store_day === currentDay)
    },
    storeCurrency() {
      if (window.SuperApp.getters.userInfo()) {
        return window.SuperApp.getters.userInfo().currency_symbol
      }
      return ''
    },
    orderTypeOptions() {
      return [
        {
          text: 'Select Order Type',
          value: '',
        },
        {
          text: 'DELIVERY',
          value: 'DELIVERY',
        },
        {
          text: 'PICKUP',
          value: 'TAKEAWAY',
        },
        {
          text: !this.isBookingPage ? 'POS' : 'DINE_IN',
          value: !this.isBookingPage ? 'POS' : 'DINE_IN',
        },
      ]
    },
    paymentModeOptions() {
      const payments = [
        {
          text: 'CASH',
          value: 'CASH',
        },
        {
          text: 'CARD',
          value: 'CARD',
        },
        {
          text: 'CARD ON DELIVERY',
          value: 'CARD_ON_DELIVERY',
        },
      ]
      if (this.editStoreInfo.orderType === 'DELIVERY' || this.editStoreInfo.orderType === 'TAKEAWAY') {
        return payments.filter(x => x.value !== 'CARD')
      }
      if (this.editStoreInfo.orderType === 'DINE_IN') {
        return payments.filter(x => x.value !== 'CARD_ON_DELIVERY')
      }
      return payments
    },
    itemMode() {
      return [
        {
          text: 'Without Item',
          value: 1,
        },
        {
          text: 'With Item',
          value: 0,
        },
      ]
    },
    userPromocodesOptions() {
      return this.promocodesList.map(x => ({
        text: [x.promo_code, 'as', x.promo_description].filter(y => y).join(' '),
        value: x.id,
      }))
    },
    userAddressOptions() {
      if (!this.phoneUserInfo.address) return []
      return this.phoneUserInfo.address.map(x => ({
        text: [x.map_address, x.flat_no, x.building, x.street, x.zipcode].filter(y => y).join(', '),
        value: x.id,
      }))
    },
    storeType() {
      if (this.cartInfo && this.cartInfo.store && this.cartInfo.store.storetype) {
        return this.cartInfo.store.storetype
      }
      return ''
    },
  },
  mounted() {
    this.getCountries()
    this.getStoreItems()
    this.getStoreTimeslots()
    if (this.isBookingPage) {
      this.editStoreInfo.orderType = 'DINE_IN'
    }
    const storeCountryCode = window.SuperApp.getters.userInfo().country_code
    if (storeCountryCode) {
      window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, storeCountryCode, '')
    }
  },
  methods: {
    valueChanged(payload) {
      this.$refs.menuCreationRef.addItemToCart(payload)
    },
    parseTo(value, to) {
      return parseFloat(parseFloat(value).toFixed(to))
    },
    async calculateTotal(type) {
      if (window.event && (window.event.key || window.event.code) === 'Tab') {
        return
      }
      // if(typeof this.editStoreInfo.promocode_id !== typeof undefined && this.editStoreInfo.promocode_id != '' && this.editStoreInfo.promocode_id > 0){
      //   this.editStoreInfo.promocode_id = ''
      //   await this.orderAddressChanged()
      // }


      let percentage = 0, fixed = 0, discountAmt = 0;
      const allowTypes = ['PERCENTAGE', 'FIXED'];
      this.cartInfo.total_price = window.SuperApp.actions.cloneDeep(this.cartInfo.total_price_original)
      if(!allowTypes.some(at => at == type)){
        this.editStoreInfo.discount_type = ''
        return false
      }
      if(type == 'PERCENTAGE'){
        this.editStoreInfo.fixed = ''
        if(typeof this.editStoreInfo.percentage === typeof undefined || this.editStoreInfo.percentage == '' || this.editStoreInfo.percentage <= 0){
          return false
        }
        percentage = parseFloat(this.editStoreInfo.percentage)
      }
      if(type == 'FIXED'){
        this.editStoreInfo.percentage = ''
        if(typeof this.editStoreInfo.fixed === typeof undefined || this.editStoreInfo.fixed == '' || this.editStoreInfo.fixed <= 0){
          return false
        }
        fixed = parseFloat(this.editStoreInfo.fixed)
      }

      

      if (this.cartInfo && this.cartInfo.total_item_price != null) {
        const subTotal = this.cartInfo.total_item_price != null ? parseFloat(this.cartInfo.total_item_price) : 0
        const shopDiscount = this.cartInfo.shop_discount != null ? parseFloat(this.cartInfo.shop_discount) : 0
        const finalPriceToCalculate = subTotal - shopDiscount
        this.editStoreInfo.discount_type = type
        if (type === 'PERCENTAGE') {
          discountAmt = this.parseTo((finalPriceToCalculate * percentage) / 100, 3)
        } else if (type === 'FIXED') {
          discountAmt = this.parseTo(fixed, 3)
        }
        if(discountAmt > 0){
          this.cartInfo.total_price = this.parseTo((this.cartInfo.total_price - discountAmt), 3)
        }
      }
    },
    removePromocode() {
      this.editStoreInfo.promocode_id = ''
      this.orderAddressChanged()
    },
    applyPromocode() {
      this.orderAddressChanged()
    },
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getStoreTimeslots() {
      const { shopTimeslotDetails } = useStoreUi()
      shopTimeslotDetails()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    orderTypeChanged(canCall = '') {
      if (canCall === 'IGNORE') {
        return
      }
      if (this.lastOrderType === 'POS') {
        this.editStoreInfo = {
          ...this.editStoreInfo,
          id: 0,
          firstName: '',
          lastName: '',
          email: '',
          flat: '',
          flatName: '',
          paymentMode: '',
          phoneNumber: '',
          addressId: '',
          comments: '',
          schedule: '',
        }
      }
      this.lastOrderType = this.editStoreInfo.orderType
      if (this.editStoreInfo.orderType === 'DINE_IN') {
        this.editStoreInfo.without_item = '1'
        this.disableBookOrder = true
        if (this.editStoreInfo.paymentMode === 'CARD_ON_DELIVERY') {
          this.editStoreInfo.paymentMode = 'CASH'
        }
      } else if (this.editStoreInfo.orderType === 'POS') {
        this.getDefaultStoreUser()
      } else {
        if (this.editStoreInfo.paymentMode === 'CARD') {
          this.editStoreInfo.paymentMode = 'CASH'
        }
        this.disableBookOrder = false
      }
    },
    resetForm() {
      this.editStoreInfo = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        country_id: '',
        city_id: '',
        flat: '',
        flatName: '',
        orderType: '',
        paymentMode: '',
        addressId: '',
        comments: '',
        schedule: '',
        without_item: 0,
        no_of_guest: 1,
      }
      if (this.isBookingPage) {
        this.editStoreInfo.orderType = 'DINE_IN'
      }
    },
    setCountryCity() {
      const storeInfo = window.SuperApp.getters.userInfo()
      this.editStoreInfo.country_id = storeInfo.country_id
      this.changeUserCity()
      this.editStoreInfo.city_id = storeInfo.city_id
    },
    setAddressExtraInfo() {
      if (this.phoneUserInfo.address) {
        // eslint-disable-next-line eqeqeq
        const isAddressExist = this.phoneUserInfo.address.find(x => x.id == this.editStoreInfo.addressId)
        if (isAddressExist) {
          this.editStoreInfo.building = isAddressExist.building
          this.editStoreInfo.flat_no = isAddressExist.flat_no
        }
      }
    },
    orderAddressChanged(clearExtra = 'YES') {
      if (this.editStoreInfo.addressId && this.editStoreInfo.orderType) {
        this.setAddressExtraInfo()
        const payload = []
        payload.push(`user_address_id=${this.editStoreInfo.addressId}`)
        payload.push(`order_type=${this.editStoreInfo.orderType}`)
        if(typeof this.editStoreInfo.promocode_id !== typeof undefined && this.editStoreInfo.promocode_id > 0){
          payload.push(`promocode_id=${this.editStoreInfo.promocode_id}`)
        }
        // if (this.editStoreInfo.promocode_id && this.editStoreInfo.orderType === 'POS') {
        // }
        if (clearExtra === 'YES') {
          if (this.editStoreInfo.percentage) {
            this.editStoreInfo.percentage = ''
            this.editStoreInfo.discount_type = ''
            this.calculateTotal('PERCENTAGE')
          }
          if (this.editStoreInfo.fixed) {
            this.editStoreInfo.fixed = ''
            this.editStoreInfo.discount_type = ''
            this.calculateTotal('FIXED')
          }
        }
        this.$refs.menuCreationRef.getCartList(`&${payload.join('&')}`)
      }
    },
    clearCoupon() {
      if (this.editStoreInfo.percentage || this.editStoreInfo.fixed) {
        if (this.editStoreInfo.promocode_id) {
          this.editStoreInfo.promocode_id = ''
          this.orderAddressChanged('NO')
        }
      }
    },
    userCardInfo(payload) {
      this.cartInfo = payload
      this.calculateTotal(this.editStoreInfo.discount_type)
    },
    getStoreItems() {
      if (!this.showMenuButton) {
        return
      }
      const { shopItems } = useStoreUi()

      shopItems()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeItemsData = data.responseData
            this.storeItemsData.products = this.storeItemsData.products.map(x => ({
              ...x,
              showFull: false,
            }))
            this.storeItemsData.categories = this.storeItemsData.categories.map(x => ({
              ...x,
              isSelected: false,
            }))
            if (Array.isArray(this.storeItemsData.products)) {
              const categoryId = this.storeItemsData.categories.map(x => x.id)
              this.storeItemsData.products = this.storeItemsData.products.filter(x => categoryId.includes(x.store_category_id))
            }
            const storeType = window.SuperApp.getters.userInfo()
            this.storeItemsData.currency_symbol = storeType.currency_symbol
            this.storeItemsData.store_name = storeType.store_name
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    setPlace(place) {
      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.userAddressInfo.map_address = place.formatted_address
        this.userAddressInfo.latitude = marker.lat
        this.userAddressInfo.longitude = marker.lng
        this.userAddressInfo.building = place.name
        this.markers = {
          position: marker,
        }
        this.center = marker
      }
    },
    setAdress(place) {
      this.userAddressInfo = JSON.parse(JSON.stringify(place))
    },
    createUserAddressModal() {
      this.createUserAddressModalShow = true
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
    },
    createOrder() {
      this.isDataSubmitted = true
      this.$nextTick(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.isDataSubmitted = false
        const formData = new FormData()
        if (this.$refs.calenderRef) {
          this.editStoreInfo.schedule = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
        }
        formData.append('country_id', this.editStoreInfo.country_id)
        formData.append('user_id', this.phoneUserInfo.id)
        formData.append('cod', this.editStoreInfo.cod)
        if (!this.isBookingPage) formData.append('user_address_id', this.editStoreInfo.addressId)
        formData.append('without_item', !this.isBookingPage ? this.editStoreInfo.without_item : 1)
        formData.append('description', this.editStoreInfo.comments)
        formData.append('order_type', this.editStoreInfo.orderType)
        formData.append('city_id', this.editStoreInfo.city_id)
        if (!this.isBookingPage) formData.append('payment_mode', this.editStoreInfo.paymentMode)
        if (this.editStoreInfo.orderType === 'DINE_IN') {
          formData.append('delivery_date', moment(this.editStoreInfo.schedule).format('YYYY-MM-DD HH:mm:ss A'))
          formData.append('year', moment(this.editStoreInfo.schedule).format('YYYY'))
          formData.append('month', moment(this.editStoreInfo.schedule).format('MM'))
          formData.append('day', moment(this.editStoreInfo.schedule).format('DD'))
          formData.append('time', moment(this.editStoreInfo.schedule).format('HH:mm'))
          formData.append('no_of_guest', this.editStoreInfo.no_of_guest)
        }
        if (this.editStoreInfo.promocode_id && this.editStoreInfo.orderType === 'POS') {
          formData.append('promocode_id', this.editStoreInfo.promocode_id)
        }
        formData.append('book', this.isBookingPage ? 1 : 0)
        formData.append('wallet', '')
        if (this.editStoreInfo.discount_type) {
          formData.append('discount_type', this.editStoreInfo.discount_type)
          formData.append('discount', this.editStoreInfo.discount_type === 'PERCENTAGE' ? this.editStoreInfo.percentage : this.editStoreInfo.fixed)
        }
        const { createManualOrder } = useStoreUi()

        showLoader()
        createManualOrder(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              showSuccessNotification(this, 'Order created successfully!')
              window.SuperApp.actions.newOrder()
              if (this.isBookingPage) {
                eventBus.$emit('user-sync-app', {
                  bookingCreated: true,
                  userId: this.phoneUserInfo.id,
                  shopName: window.SuperApp.getters.userInfo().store_name,
                  invoiceId: data.responseData.store_order_invoice_id,
                })
              }
              this.$emit('create-order', data.responseData)
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
    getCountries() {
      const { userCountry } = useUserUi()

      userCountry()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.countryPayload = data.responseData
            const country = data.responseData.map(x => ({
              text: x.country_name,
              value: x.id,
            }))
            this.countryOptions.push({
              text: 'Select Country',
              value: '',
            })
            country.forEach(element => {
              this.countryOptions.push({
                text: element.text,
                value: element.value,
              })
            })
            this.setCountryCity()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    changeUserCity() {
      this.cityOptions = []
      let cityList = []
      const isCountryExist = this.countryPayload.find(x => x.id === this.editStoreInfo.country_id)
      if (isCountryExist) {
        cityList = isCountryExist.city.map(x => ({
          text: x.city_name,
          value: x.id,
        }))
      }
      this.cityOptions.push({
        text: 'Select City',
        value: '',
      })
      cityList.forEach(element => {
        this.cityOptions.push({
          text: element.text,
          value: element.value,
        })
      })
    },
    createUser() {
      this.isDataSubmitted = true
      this.$nextTick(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.isDataSubmitted = false
        const userInfo = {
          phone: this.getPhoneNumber(),
          email: this.editStoreInfo.email,
          first_name: this.editStoreInfo.firstName,
          last_name: this.editStoreInfo.lastName,
          country_code: this.phoneNumberPayload.countryCallingCode,
          country_id: this.editStoreInfo.country_id,
          city_id: this.editStoreInfo.city_id,
        }
        showLoader()
        const { createUser } = useStoreUi()

        createUser(userInfo)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.phoneUserInfo = data.responseData
              this.shouldCreateUser = 'order'
              showSuccessNotification(this, 'User created successfully!')
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      })
    },
    createUserAddress() {
      const address = this.userAddressInfo
      const { createUserAddress } = useStoreUi()
      const formData = new FormData()
      formData.append('map_address', address.map_address)
      formData.append('latitude', address.latitude)
      formData.append('longitude', address.longitude)
      formData.append('flat_no', address.flat_no)
      formData.append('street', address.street)
      formData.append('address_type', address.address_type)
      formData.append('building', address.building)
      formData.append('user_id', this.phoneUserInfo.id)
      formData.append('zipcode', address.zipcode)
      showLoader()
      createUserAddress(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.editStoreInfo.addressId = data.responseData.id
            this.getUserByPhone()
            showSuccessNotification(this, data.message)
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getPhoneNumber() {
      let phoneNo = this.editStoreInfo.phoneNumber
      if (phoneNo) {
        phoneNo = phoneNo.replace(/[^a-zA-Z0-9]/g, '')
      }
      return phoneNo
    },
    getUserByPhone() {
      const { searchByPhoneNumber } = useStoreUi()
      showLoader()
      searchByPhoneNumber(this.getPhoneNumber())
        .then(({ data }) => {
          this.processDetails(data)
          hideLoader()
        })
        .catch(error => {
          this.phoneUserInfo = {}
          if (this.$refs.menuCreationRef) {
            this.$refs.menuCreationRef.userCartItems = []
          }
          this.shouldCreateUser = 'user'
          showErrorNotification(this, error)
        })
    },
    searchByEmail() {
      const { searchByEmail } = useStoreUi()
      showLoader()
      searchByEmail(this.editStoreInfo.email)
        .then(({ data }) => {
          this.processDetails(data)
          hideLoader()
        })
        .catch(error => {
          this.phoneUserInfo = {}
          if (this.$refs.menuCreationRef) {
            this.$refs.menuCreationRef.userCartItems = []
          }
          this.shouldCreateUser = 'user'
          showErrorNotification(this, error)
        })
    },
    shopPromocodes() {
      const { shopPromocodes } = useStoreUi()

      shopPromocodes(`?user_id=${this.phoneUserInfo.id}&store_id=${window.SuperApp.getters.userInfo().id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.promocodesList = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    processDetails(data) {
      if (data.statusCode === '200') {
        this.phoneUserInfo = data.responseData
        this.editStoreInfo.id = this.phoneUserInfo.id
        this.editStoreInfo.firstName = this.phoneUserInfo.first_name
        this.editStoreInfo.lastName = this.phoneUserInfo.last_name
        this.editStoreInfo.email = this.phoneUserInfo.email
        this.editStoreInfo.country_id = this.phoneUserInfo.country_id
        this.editStoreInfo.city_id = this.phoneUserInfo.city_id
        this.editStoreInfo.without_item = 0
        this.orderTypeChanged('IGNORE')
        if (this.phoneUserInfo.address && this.phoneUserInfo.address[0]) {
          this.editStoreInfo.flat = this.phoneUserInfo.address[0].map_address
          this.editStoreInfo.flatName = this.phoneUserInfo.address[0].building
          this.editStoreInfo.addressId = this.phoneUserInfo.address[0].id
          this.setAddressExtraInfo()
        }
        this.setCountryCity()
        if (this.$refs.menuCreationRef) {
          this.$nextTick(() => {
            if (this.phoneUserInfo.country_code) {
              window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, this.phoneUserInfo.country_code, this.phoneUserInfo.mobile)
            }
            const payload = []
            payload.push(`user_address_id=${this.editStoreInfo.addressId}`)
            payload.push(`order_type=${this.editStoreInfo.orderType}`)
            if (this.editStoreInfo.promocode_id && this.editStoreInfo.orderType === 'POS') {
              payload.push(`promocode_id=${this.editStoreInfo.promocode_id}`)
            }
            this.$refs.menuCreationRef.getCartList(`&${payload.join('&')}`)
          })
        }
        this.shopPromocodes()
      } else {
        this.phoneUserInfo = {}
        this.$refs.menuCreationRef.userCartItems = []
        this.shouldCreateUser = 'user'
      }
    },
    getDefaultStoreUser() {
      const { getDefaultStoreUser } = useStoreUi()
      showLoader()
      getDefaultStoreUser()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const userInfo = data.responseData
            this.phoneUserInfo = userInfo
            this.editStoreInfo.id = userInfo.id
            this.editStoreInfo.firstName = userInfo.first_name
            this.editStoreInfo.phoneNumber = userInfo.mobile
            this.editStoreInfo.lastName = ''
            this.editStoreInfo.email = userInfo.email
            this.editStoreInfo.country_id = userInfo.country_id
            this.editStoreInfo.city_id = userInfo.city_id
            this.editStoreInfo.without_item = 0
            this.disableBookOrder = false
            if (userInfo.address && userInfo.address[0]) {
              this.editStoreInfo.flat = userInfo.address[0].map_address
              this.editStoreInfo.flatName = userInfo.address[0].building
              this.editStoreInfo.addressId = this.phoneUserInfo.address[0].id
              this.setAddressExtraInfo()
            }
            this.setCountryCity()
            if (this.$refs.menuCreationRef) {
              this.$nextTick(() => {
                if (this.phoneUserInfo.country_code) {
                  window.SuperApp.helper.setCountryCode(this.$refs.countryCodeRef, this.phoneUserInfo.country_code, this.phoneUserInfo.mobile)
                }
                const payload = []
                payload.push(`user_address_id=${this.editStoreInfo.addressId}`)
                payload.push(`order_type=${this.editStoreInfo.orderType}`)
                if (this.editStoreInfo.promocode_id && this.editStoreInfo.orderType === 'POS') {
                  payload.push(`promocode_id=${this.editStoreInfo.promocode_id}`)
                }
                this.$refs.menuCreationRef.getCartList(`&${payload.join('&')}`)
              })
            }
            this.shopPromocodes()
          } else {
            this.phoneUserInfo = {}
            this.$refs.menuCreationRef.userCartItems = []
            this.shouldCreateUser = 'user'
          }
          hideLoader()
        })
        .catch(error => {
          this.phoneUserInfo = {}
          this.$refs.menuCreationRef.userCartItems = []
          this.shouldCreateUser = 'user'
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.grid-columns-template-wrapper {
  grid-template-columns: 40% 30% 30%;
  display: grid;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.phone-number-align {
  margin: auto 0;
  margin-top: 0;

  .vue-phone-number-input {
    width: 100%;
  }

  .input-group {
    flex-wrap: nowrap;
  }
}

.order-creation-modal .max-auto,
#menuCreationModal .modal-body {
  max-height: 75vh;
  overflow-y: auto;
}

.order-creation-modal {
  .ecommerce-card .item-img img {
    height: 150px;
    object-fit: cover;
  }

  .ecommerce-application .grid-view .ecommerce-card .item-img {
    min-height: 10rem;
  }
}

.order-creation-modal .disable-view {
  opacity: 0.6;
  pointer-events: none;
}

.modal .ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
#createOrderModal___BV_modal_body_{
  background-color: #f2f2f2;
}
#createOrderModal .ecommerce-application .grid-view {
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.order-creation-modal .order-type-images {
  img {
    width: 20px;
  }

  .w-common-radio {
    width: 115px;
  }

  .custom-control-label {
    display: flex;
    align-items: center;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    transform: translateY(-50%);
    top: 50%;
  }

  .custom-radio.custom-control {
    border: 1px solid #d8d6de;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 3px;
  }

  span {
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 3px;
  }

  @media only screen and (max-width: 767px) {
    span {
      display: none;
    }

    .w-common-radio {
      width: auto;
    }
  }
}
</style>
