<template>
  <b-card
    v-if="that.storeList"
    class="ecommerce-card pt-pb"
    :class="{ 'disable-effect-stock': !product.is_available }"
    no-body
  >
    <div class="item-img text-center set-extra-images">
      <div
        class="d-flex extra-image-ui"
        :class="{ 'justify-content-end': !product.is_veg }"
      >
        <b-img
          v-if="product.is_veg"
          class="food-type-image"
          :src="$helpers.getIcons(product.is_veg ? (product.is_veg === 'Non Veg' ? 'non-veg' : 'veg') : '')"
        />
        <div class="wrapper-images d-flex">
          <b-img
            v-if="product.is_best_seller === 1"
            class="best-seller-image"
            :src="$helpers.getIcons('bestseller')"
          />
          <b-img
            v-if="product.is_recommended === 1"
            class="recommended-image"
            :src="$helpers.getIcons('recommended')"
          />
        </div>
      </div>
      <b-img
        v-if="!product.is_available"
        class="out-of-stock-image"
        :src="$helpers.getIcons('stock-out')"
      />
      <div class="w-100">
        <figure
          @mouseenter="$superApp.helper.zoomMagic"
          @mouseout="$superApp.helper.zoomOut"
        >
          <v-zoomer
            :zoomed.sync="$superApp.zoomed"
            :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
          >
            <b-img
              :alt="`${product.item_name}`"
              fluid
              class="card-img-top"
              :src="$helpers.imageHelper(product.picture)"
              @error="$helpers.imageError"
            />
          </v-zoomer>
        </figure>
      </div>
    </div>

    <!-- Product Details -->
    <b-card-body>
      <div
        v-if="!isMobile"
        class="item-wrapper"
      >
        <div>
          <h6 class="item-price fs-0p875rem">
            {{ that.storeList.currency_symbol }} {{ product.product_offer }}
          </h6>
        </div>
      </div>
      <h6 class="item-names">
        <b-link class="text-body fs-0p875rem">
          {{ product.item_name }}
          <span v-if="product.unit_value && product.unit_id && product.main_unit"> ({{ product.unit_value }} {{ product.main_unit.name }})</span>
        </b-link>
      </h6>
      <b-card-text class="fs-0p875rem">
        SKU: {{ product.sku }}
      </b-card-text>
      <b-card-text
        v-if="product.item_description && !hideDesc"
        class="item-description d-block text-break auto-text fs-0p875rem"
        :class="{ 'mb-0': that.itemView === 'list-view' }"
      >
        {{ product.showFull ? product.item_description : that.getText(product.item_description) }}
        <span
          v-if="that.canShowLabel(product.item_description)"
          class="small font-weight-bold text-right fs-0p875rem"
          style="word-break: keep-all !important"
          @click="$emit('toggle-show', product)"
        >
          <feather-icon
            :icon="product.showFull ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            class="cursor-pointer"
            size="15"
          />
        </span>
      </b-card-text>
      <div
        v-if="isMobile"
        class="d-flex align-items-center fs-0p875rem"
      >
        <div
          class="item-wrapper"
        >
          <h6 class="item-price fs-0p875rem">
            {{ that.storeList.currency_symbol }}{{ product.product_offer }}
          </h6>
        </div>
        <div
          class="item-options text-center mt-0 cart-item-qty"
          style="width: 50%;"
        >
          <form-spin-button
            v-if="that.isAddedToCart(product)"
            :product="product"
            class="spin-button-menu-creation"
            :cart-product="that.multipleProduct(product)"
            @value-changed="that.addItemToCart"
          />
          <b-button
            v-if="!that.isAddedToCart(product)"
            :variant="that.isAddedToCart(product) ? 'danger' : 'primary'"
            tag="a"
            class="btn-cart"
            @click="that.setItemAddOnData(product)"
          >
            <span>{{ that.isAddedToCart(product) ? 'Remove' : 'Add' }}</span>
          </b-button>
        </div>
      </div>
    </b-card-body>

    <div
      v-if="!isMobile"
      class="item-options text-center fs-0p875rem"
    >
      <b-button
        v-if="that.isAddedToCart(product)"
        :variant="isMobile ? '' : 'primary'"
        class="cart-item-qty btn-cart"
      >
        <form-spin-button
          :product="product"
          class="spin-button-menu-creation"
          :cart-product="that.multipleProduct(product)"
          @value-changed="that.addItemToCart"
        />
      </b-button>
      <b-button
        v-if="!that.isAddedToCart(product)"
        :variant="that.isAddedToCart(product) ? 'danger' : 'primary'"
        tag="a"
        class="btn-cart"
        @click="that.setItemAddOnData(product)"
      >
        <span>{{ that.isAddedToCart(product) ? 'Remove' : 'Add' }}</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg, BCardBody, BLink, BButton, BCardText } from 'bootstrap-vue'
import FormSpinButton from '@/views/shared/FormSpinButton.vue'

export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BCardBody,
    BLink,
    BButton,
    FormSpinButton,
  },
  props: {
    that: {
      type: Object,
      default: () => {},
    },
    filteredStore: {
      type: Object,
      default: () => {},
    },
    product: {
      type: Object,
      default: () => {},
    },
    hideDesc: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters['app/windowSize'] <= 767
    },
  },
}
</script>
