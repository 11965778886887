<template>
  <div
    class="shop-booking-table user-service-calender app-calendar overflow-hidden"
    style="border-top-left-radius: 0"
  >
    <div class="row">
      <div class="col-md-8">
        <div class="position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h4>{{ $t('Create Bookings') }}</h4>
        <order-creation
          ref="orderForm"
          :is-booking-page="true"
          :show-menu-button="false"
          @create-order="orderCreated"
        />
      </div>
    </div>
    <b-modal
      id="viewOrderInfoModal"
      v-model="showOrderInfo"
      :no-close-on-backdrop="true"
      :title="$t('Order Details')"
      size="lg"
      :cancel-title="$t('Dismiss')"
      @hidden="showOrderInfo = false"
    >
      <order-detail
        :is-booking="true"
        :active-order-detail="activeOrderDetail"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment'
import listPlugin from '@fullcalendar/list'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import OrderCreation from './shared/OrderCreation.vue'
import { useStoreUi } from './useStore'
import OrderDetail from './shared/OrderDetail.vue'

export default {
  components: {
    BModal,
    FullCalendar,
    OrderCreation,
    OrderDetail,
  },
  data() {
    return {
      showOrderInfo: false,
      activeOrderDetail: null,
      paymentInfo: {
        couponCode: {},
        isPromocodeApplied: false,
      },
      promocodeList: [],
      isPromocodeVisible: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,
        eventClassNames({ event: calendarEvent }) {
          const calendarsColor = {
            AtStore: 'primary',
            AtCustomer: 'success',
          }
          // eslint-disable-next-line no-underscore-dangle
          const colorName = calendarsColor[calendarEvent._def.extendedProps.calendar]

          return [`bg-light-${colorName}`]
        },
        rerenderDelay: 350,
        events: [],
        // eslint-disable-next-line no-unused-vars
        eventClick(data, event, view) {
          window.storeBookings.viewServiceHistory(data.event.extendedProps.payload)
        },
      },
      paymentMode: 'CASH',
      optionsLabel: [
        {
          label: 'Cash',
          value: 'CASH',
        },
        {
          label: 'Card',
          value: 'CARD',
        },
        {
          label: 'Card on Delivery',
          value: 'CARD_ON_DELIVERY',
        },
      ],
      bookedTimeSlotData: {},
      calendarsColor: {
        AtStore: 'primary',
        AtCustomer: 'success',
      },
      providerUserAddress: {
        provider_address: {},
        user_address: {},
      },
      bookedTimeSlots: [],
      activeAddress: {
        user: true,
        provider: true,
      },
    }
  },
  mounted() {
    this.getTimeSlots()
    window.storeBookings = this
    document.addEventListener('click', this.documentClicked)
  },
  destroyed() {
    document.removeEventListener('click', this.documentClicked)
  },
  methods: {
    documentClicked(event) {
      if (event && event.target && event.target.parentElement && event.target.parentElement.className === 'fc-button-group') {
        if (event.target.innerText === 'Week') {
          const groups = window.SuperApp.actions.groupArray(this.calendarOptions.events, x => new Date(x.date).toLocaleDateString())
          let newGroup = []
          groups.forEach(item => {
            item.forEach(x => {
              // eslint-disable-next-line no-param-reassign
              x.allDay = false
            })
            if (item.length > 3) {
              item.forEach(x => {
                // eslint-disable-next-line no-param-reassign
                x.allDay = true
              })
            }
            newGroup = [...item, ...newGroup]
          })
          this.calendarOptions.events = newGroup
        } else {
          this.calendarOptions.events = this.calendarOptions.events.map(x => ({
            ...x,
            allDay: false,
          }))
        }
      }
    },
    orderCreated() {
      if (this.$refs.orderForm) {
        this.$refs.orderForm.resetForm()
      }
      this.getTimeSlots()
    },
    viewServiceHistory(payload) {
      if (!payload.order_id) {
        showDangerNotification(this, 'No order found for this record!')
        return
      }
      showLoader()
      const { agentOrderDetail } = useStoreUi()
      agentOrderDetail(payload.order_id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.activeOrderDetail = data.responseData
            this.showOrderInfo = true
            hideLoader()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getTimeSlots() {
      const { bookedTimeSlots } = useStoreUi()
      showLoader()
      bookedTimeSlots('')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const events = Array.isArray(data.responseData) ? data.responseData : data.responseData.timeslots
            this.calendarOptions.events = events.map(x => ({
              title: `${x.orders[0] && x.orders[0].number_guests ? `(${x.orders[0].number_guests} persons.) ` : ''}Request ${x.orders && x.orders[0] ? `for ${x.orders[0].store_order_invoice_id}` : ''}`,
              date: new Date(`${moment(new Date(x.booking_date)).format('YYYY-MM-DD')} ${x.time ? x.time : ''}`),
              payload: x,
            }))
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';

.shop-booking-table {
  .vue-phone-number-input {
    width: 100%;
  }

  .booking-page-phone {
    margin-top: 10px;
  }
}
</style>

<style scoped>
.service-checkout-right-section {
  background: rgb(247, 247, 247);
  padding-left: 0;
}

.service-checkout-card {
  box-shadow: rgb(34 41 47 / 10%) 0px 4px 24px 0px !important;
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.service-checkout-card table.table th,
.service-checkout-card table.table td {
  padding: 2px 1rem;
}

@media screen and (min-width: 768px) {
  .col-md-5-5 {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-margin-bottom-checkout {
    margin-bottom: 10px;
  }

  .service-checkout-right-section {
    background: #fff;
    padding-left: 1rem;
  }

  .service-checkout-right-section .card-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}
</style>
